import { lazy, Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import { GlobalStyle } from './global.styles';


const Home = lazy(() =>
  import('./routes/home/home.component')
);

function App() {
  return (
    <Suspense >
      <GlobalStyle />
      <Routes>
          <Route path='/' element={<Home />} >
        </Route>
      </Routes>
    </Suspense>
  );
};


export default App;